.App-login-H5 {
    text-align: center;
    background-color: #ffffff;
    /* background-image: url('bg.png'); */
    width: 70vw;
    position: 'absolute';
    margin: auto;
    padding-top: 30vh;
  }
  .App-bg-H5 {
    height: 175vh;
    width: 100vw;
    position: absolute;
  }
  .App-title-bg-H5 {
    margin-top: 1.7vh;
    margin-left: 0vw;
    height: 5vh;
      width: 100vw;
    position: absolute;
  }
  .App-title-H5 {
    margin-top: 3vh;
    margin-left: 6vw;
    position: absolute;
    font-weight: bold;
    font-size: larger;
  }
  .App-size-title-H5 {
    margin-top: 8.5vh;
    margin-left: 2vw;
    height: 1.5vh;
    width: 12vw;
    position: absolute;
  }
  .App-size-num-H5 {
    margin-top: 8vh;
    margin-left: 15vw;
    height: 2vh;
    width: 40vw;
    position: absolute;
  }
  .App-day-title-H5 {
    margin-top: 8.5vh;
    margin-left: 57vw;
    height: 1.5vh;
    width: 12vw;
    position: absolute;
  }
  .App-day-num-H5 {
    margin-top: 8vh;
    margin-left: 63vw;
    height: 10vh;
    width: 35vw;
    position: absolute;
  }
  .App-device-title-H5 {
    margin-top: 13vh;
    margin-left: 2vw;
    height: 1.5vh;
    width: 12vw;
    position: absolute;
  }
  .App-device-num-H5 {
    margin-top: 13vh;
    margin-left: 15vw;
    height: 10vh;
    width: 45vw;
    position: absolute;
  }
  .App-warning-title-H5 {
    margin-top: 13vh;
    margin-left: 57vw;
    height: 1.5vh;
    width: 12vw;
    position: absolute;
  }
  .App-warning-num-H5 {
    margin-top: 13vh;
    margin-left: 63vw;
    height: 10vh;
    width: 35vw;
    position: absolute;
  }
  .App-center-bg-H5 {
    margin-top: 18vh;
    margin-left: 2vw;
    height: 49vh;
      width: 96vw;
    position: absolute;
  }
  .App-center-title-H5 {
    margin-top: 19vh;
    margin-left: 10vw;
    height: 2vh;
      width: 20vw;
    position: absolute;
  }
  .App-map-H5 {
    margin-top: 18vh;
    margin-left: 2vw;
      width: 96vw;
      height: 50vh;
    position: absolute;
  }
  .App-rateEchart-bg-H5 {
    margin-top: 70vh;
    margin-left: 2vw;
    height: 22vh;
      width: 46vw;
    position: absolute;
  }
  .App-rateEchart-title-H5 {
    margin-top: 70.5vh;
    margin-left: 20vw;
    height: 1.5vh;
      width: 12vw;
    position: absolute;
  }
  .App-rateEchart-H5 {
    margin-top: 75vh;
    margin-left: 1vw;
    height: 25vh;
      width: 48vw;
    position: absolute;
  }
  .App-funnelEchart-bg-H5 {
    margin-top: 70vh;
    margin-left: 52vw;
    height: 22vh;
      width: 46vw;
    position: absolute;
  }
  .App-funnelEchart-title-H5 {
    margin-top: 70.5vh;
    margin-left: 70vw;
    height: 1.5vh;
      width: 10vw;
    position: absolute;
  }
  .App-funnelEchart-H5 {
    margin-top: 70vh;
    margin-left: 52vw;
    height: 27vh;
      width: 46vw;
    position: absolute;
  }
  .App-lineEchart-bg-H5 {
    margin-top: 95vh;
    margin-left: 2vw;
    height: 24vh;
      width: 46vw;
    position: absolute;
  }
  .App-lineEchart-title-H5 {
    margin-top: 95.5vh;
    margin-left: 20vw;
    height: 1.5vh;
      width: 12vw;
    position: absolute;
  }
  .App-lineEchart-H5 {
    margin-top: 96vh;
    margin-left: 6vw;
    height: 25vh;
      width: 40vw;
    position: absolute;
  }
  .App-pieEchart-bg-H5 {
    margin-top: 95vh;
    margin-left: 52vw;
    height: 24vh;
      width: 46vw;
    position: absolute;
  }
  .App-pieEchart-title-H5 {
    margin-top: 95.5vh;
    margin-left: 70vw;
    height: 1.5vh;
      width: 12vw;
    position: absolute;
  }
  .App-pieEchart-H5 {
    margin-top: 101vh;
    margin-left: 55vw;
    height: 20vh;
      width: 40vw;
    position: absolute;
  }
  .App-barEchart-bg-H5 {
    margin-top: 122vh;
    margin-left: 2vw;
    height: 23vh;
      width: 46vw;
    position: absolute;
  }
  .App-barEchart-title-H5 {
    margin-top: 122.5vh;
    margin-left: 20vw;
    height: 1.5vh;
      width: 12vw;
    position: absolute;
  }
  .App-barEchart-H5 {
    margin-top: 122vh;
    margin-left: 4vw;
      width: 46vw;
    height: 25vh;
    position: absolute;
  }
  .App-radarEchart-bg-H5 {
    margin-top: 122vh;
    margin-left: 52vw;
    height: 23vh;
      width: 46vw;
    position: absolute;
  }
  .App-radarEchart-title-H5 {
    margin-top: 122.5vh;
    margin-left: 72vw;
    height: 1.5vh;
      width: 8vw;
    position: absolute;
  }
  .App-radarEchart-H5 {
    margin-top: 125vh;
    margin-left: 55vw;
      width: 40vw;
    height: 20vh;
    position: absolute;
  }

  .App-bottom-bg-H5 {
    margin-top: 149vh;
    margin-left: 2vw;
    height: 23vh;
      width: 96vw;
    position: absolute;
  }
  .App-infolist-H5 {
    margin-top: 153vh;
    margin-left: 0vw;
    position: absolute;
    font-weight: bold;
    font-size: larger;
    color: rgb(124, 168, 255);
  }
  .App-logo-H5 {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo-H5 {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header-H5 {
    color: white;
  }
  