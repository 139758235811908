.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}
.App-login {
  text-align: center;
  background-color: #ffffff;
  /* background-image: url('bg.png'); */
  width: 20vw;
  position: 'absolute';
  margin: auto;
  padding-top: 30vh;
}
.App-bg {
  height: 100vh;
  width: 100vw;
  position: absolute;
}
.App-title-bg {
  margin-top: 1.7vh;
  margin-left: 12vw;
  height: 7.5vh;
	width: 80vw;
  position: absolute;
}
.App-center-bg {
  margin-top: 22vh;
  margin-left: 27vw;
  height: 49vh;
	width: 46vw;
  position: absolute;
}
.App-center-title {
  margin-top: 23vh;
  margin-left: 30.5vw;
  height: 2vh;
	width: 10vw;
  position: absolute;
}
.App-bottom-bg {
  margin-top: 74vh;
  margin-left: 27vw;
  height: 23vh;
	width: 46vw;
  position: absolute;
}
.App-clock {
  position: absolute;
  margin-top: 3.3vh;
  margin-left: 82vw;
}
.App-title {
  margin-top: 3vh;
  margin-left: 6vw;
  position: absolute;
  font-weight: bold;
  font-size: larger;
}
.App-size-num {
  margin-top: 12vh;
  margin-left: 1.7vw;
  height: 5vh;
  width: 23vw;
  position: absolute;
}
.App-day-num {
  margin-top: 12vh;
  margin-left: 30vw;
  height: 5vh;
  width: 23vw;
  position: absolute;
}
.App-device-num {
  margin-top: 12vh;
  margin-left: 52vw;
  height: 5vh;
  width: 23vw;
  position: absolute;
}
.App-warning-num {
  margin-top: 12vh;
  margin-left: 75vw;
  height: 5vh;
  width: 23vw;
  position: absolute;
}
.App-infolist {
  margin-top: 78vh;
  margin-left: 25vw;
  position: absolute;
  font-weight: bold;
  font-size: larger;
  color: rgb(124, 168, 255);
}
.App-map {
  margin-top: 20vh;
  margin-left: 30vw;
	width: 40vw;
	height: 50vh;
  position: absolute;
}
.App-rateEchart-bg {
  margin-top: 22vh;
  margin-left: 2vw;
  height: 22vh;
	width: 24vw;
  position: absolute;
}
.App-rateEchart-title {
  margin-top: 23vh;
  margin-left: 11vw;
  height: 1.5vh;
	width: 6vw;
  position: absolute;
}
.App-rateEchart {
  margin-top: 27vh;
  margin-left: 6vw;
  height: 25vh;
	width: 15vw;
  position: absolute;
}
.App-funnelEchart-bg {
  margin-top: 22vh;
  margin-left: 74.5vw;
  height: 21vh;
	width: 24vw;
  position: absolute;
}
.App-funnelEchart-title {
  margin-top: 23vh;
  margin-left: 84vw;
  height: 1.5vh;
	width: 5vw;
  position: absolute;
}
.App-funnelEchart {
  margin-top: 22vh;
  margin-left: 78vw;
  height: 27vh;
	width: 16vw;
  position: absolute;
}
.App-lineEchart-bg {
  margin-top: 47vh;
  margin-left: 2vw;
  height: 24vh;
	width: 24vw;
  position: absolute;
}
.App-lineEchart-title {
  margin-top: 48vh;
  margin-left: 11vw;
  height: 1.5vh;
	width: 6vw;
  position: absolute;
}
.App-lineEchart {
  margin-top: 48vh;
  margin-left: 3vw;
  height: 25vh;
	width: 20vw;
  position: absolute;
}
.App-pieEchart-bg {
  margin-top: 47vh;
  margin-left: 74.5vw;
  height: 26vh;
	width: 24vw;
  position: absolute;
}
.App-pieEchart-title {
  margin-top: 48vh;
  margin-left: 84vw;
  height: 1.5vh;
	width: 6vw;
  position: absolute;
}
.App-pieEchart {
  margin-top: 51vh;
  margin-left: 82vw;
  height: 20vh;
	width: 10vw;
  position: absolute;
}
.App-barEchart-bg {
  margin-top: 74vh;
  margin-left: 2vw;
  height: 23vh;
	width: 24vw;
  position: absolute;
}
.App-barEchart-title {
  margin-top: 75vh;
  margin-left: 11vw;
  height: 1.5vh;
	width: 6vw;
  position: absolute;
}
.App-barEchart {
  margin-top: 75vh;
  margin-left: 3vw;
	width: 22vw;
  height: 25vh;
  position: absolute;
}
.App-radarEchart-bg {
  margin-top: 74vh;
  margin-left: 74.5vw;
  height: 23vh;
	width: 24vw;
  position: absolute;
}
.App-radarEchart-title {
  margin-top: 75vh;
  margin-left: 84vw;
  height: 1.5vh;
	width: 4vw;
  position: absolute;
}
.App-radarEchart {
  margin-top: 78vh;
  margin-left: 79vw;
	width: 15vw;
  height: 20vh;
  position: absolute;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: white;
}
