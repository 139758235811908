.Item {
    text-align: center;
    font-weight: bold;
    height: 4vh;
    display: flex;
    flex-direction: row;
    font-size: medium;
}
.Info1 {
    width: 15vw;
}
.Info2 {
    width: 15vw;
}
.Info3 {
    width: 15vw;
}